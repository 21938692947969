export const Fonts = {
  // Font Types
  fontRegular: '"geomantist", sans-serif',
  fontLogo: '"geomantist", sans-serif',

  // Font Weights
  weightLight: 300,
  weightStd: 400,
  weightBold: 500,
};
