import React from 'react';

export interface IIconProviderProps {}

export interface IIconProviderState {}

export function IconProvider() {
  return (
    <icon-provider>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ display: 'none' }}
      >
        <symbol
          id="grubhub"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 841.89 595.276"
        >
          <path
            d="M279.637 378.476c1.738-.492 3.574-.692 5.371-.473 2.6.268 5.126 1.401 6.985 3.251 2.444 2.348 3.598 5.818 3.43 9.166-.008 1.03.02 2.061-.01 3.09-.037.455-.504.676-.886.8-2.578.917-5.142 1.874-7.726 2.78-.371.187-.732-.043-1.051-.222-.19-.675-.116-1.38-.126-2.071-.008-1.487.096-3.05-.553-4.433-.399-.914-1.187-1.691-2.176-1.914-1.519-.249-3.023.403-4.202 1.319-1.496 1.128-2.315 2.922-2.721 4.708-.25 1.294-.406 2.61-.378 3.929l.001 17.383c.02 2.48-.144 5.004.472 7.433.309 1.068.796 2.222 1.83 2.773 1.03.546 2.262.288 3.276-.156 1.643-.728 2.794-2.251 3.445-3.886.604-1.461.883-3.04.972-4.614.023-1.49.002-2.982.011-4.474-1.26.454-2.521.905-3.78 1.364-.39.159-.884.045-1.11-.323-.11-.304-.095-.632-.102-.949.013-2.317-.01-4.634.012-6.951-.049-.53.308-1.002.801-1.166 4.285-1.56 8.575-3.109 12.86-4.669.532-.197 1.193.215 1.139.808.008 4.208 0 8.417.003 12.626-.032 2.08.093 4.166-.134 6.239-.42 4.459-2.17 8.871-5.313 12.113-2.602 2.666-6.065 4.516-9.757 5.068l-.085.06c-1.032.062-2.065.217-3.1.133-2.869-.138-5.624-1.459-7.65-3.473-2.63-2.594-3.869-6.323-3.919-9.963-.006-8.76-.001-17.52-.002-26.28.093-4.875 1.857-9.75 5.083-13.433 2.383-2.718 5.61-4.652 9.09-5.593zm21.698 22.62c-.05-.64.586-1.176 1.205-.915 2.683.98 5.37 1.946 8.055 2.92.479.151.941.567.906 1.11 0 7.139.006 14.278-.005 21.417 2.737 1.029 5.503 1.982 8.243 2.998-.005-6.94.007-13.88 0-20.82a.982.982 0 0 1 .502-.957c.306-.087.637-.04.93.08 2.588.946 5.182 1.873 7.768 2.825.501.157 1.001.574.974 1.145.008 17.857 0 35.715.005 53.572.02.353-.086.776-.439.933-.354.185-.764.05-1.114-.072-2.577-.946-5.157-1.882-7.737-2.82-.42-.166-.834-.519-.882-.991-.032-7.451.027-14.906-.028-22.355-2.742-.978-5.48-1.971-8.215-2.965v21.71c.041.356-.15.732-.492.864-.3.055-.616.043-.905-.052-2.64-.975-5.297-1.912-7.937-2.884a1.27 1.27 0 0 1-.838-1.28c.008-17.82-.004-35.641.005-53.463z"
            fill="#333"
          />
        </symbol>
        <symbol
          id="person"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <circle
            className="cls-2"
            cx="12"
            cy="12"
            r="12"
            fill="url(#blue-teal)"
          />
          <circle fill="#ffffff" cx="12" cy="9.759" r="4" />
          <path
            fill="#ffffff"
            d="M168.142 170.045a11.942 11.942 0 0 0 7.813-2.904 7.997 7.997 0 0 0-15.626 0 11.943 11.943 0 0 0 7.813 2.904z"
            transform="translate(-156.142 -146.045)"
          />
        </symbol>
        <symbol
          id="buffer"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 350.001 350.001"
        >
          <g>
            <path d="M14.092 88.769L172.1 164.826a6.692 6.692 0 0 0 5.802 0L335.91 88.768c5.051-2.429 5.051-9.621 0-12.051L177.9.662a6.69 6.69 0 0 0-5.801 0L14.092 76.718c-5.051 2.43-5.051 9.621 0 12.051z" />
            <path d="M14.092 181.024L172.1 257.082a6.692 6.692 0 0 0 5.802 0l158.008-76.057c5.051-2.429 5.051-9.621 0-12.053l-33.336-16.044-105.881 50.962c-6.726 3.236-14.228 4.946-21.692 4.946s-14.964-1.71-21.702-4.951L47.43 152.924l-33.339 16.047c-5.05 2.433-5.05 9.624.001 12.053z" />
            <path d="M335.91 261.229l-33.336-16.047-105.881 50.965c-6.726 3.236-14.228 4.946-21.692 4.946s-14.964-1.71-21.702-4.951L47.43 245.182 14.091 261.23c-5.051 2.432-5.051 9.621 0 12.053l158.008 76.057a6.692 6.692 0 0 0 5.802 0l158.008-76.057c5.052-2.433 5.052-9.623.001-12.054z" />
          </g>
        </symbol>
        <symbol
          id="close"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.6 11.9l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.3-.7-.3-1 0L12.6 10c-.1.2-.4.2-.6 0L6 3.9c-.3-.3-.7-.3-1 0l-1 .9c-.3.3-.3.7 0 1l6.1 6.1c.1.1.1.4 0 .6L4 18.6c-.3.3-.3.7 0 1l.9.9c.3.3.7.3 1 0l6.1-6c.2-.2.5-.2.6 0l6.1 6c.3.3.7.3 1 0l.9-.9c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z" />
        </symbol>
        <symbol
          id="autocomplete-icon-rotated"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 110 109.966"
        >
          <ellipse
            className="background"
            cx="55"
            cy="54.983"
            rx="55"
            ry="54.983"
            fill="#c7ede6"
          />
          <g className="foreground" id="IPhone">
            <path
              fill="#fff"
              d="M77.524 74.65a6.017 6.017 0 0 1-4.143 7.345L50.78 88.18a5.834 5.834 0 0 1-7.158-4.25L30.557 33.686a6.017 6.017 0 0 1 4.143-7.344l22.602-6.186a5.834 5.834 0 0 1 7.158 4.25z"
            />
            <path
              fill="#4ac3af"
              d="M33.948 38.969l7.756 29.829 7.473-33.997-15.229 4.168z"
            />
            <path
              fill="#21b2d1"
              d="M43.869 77.126l.128.491 27.537-7.537-23.871-10.21-3.794 17.256z"
            />
            <path
              fill="#fbe158"
              d="M53.429 33.637l-5.113 23.262 25.4 10.864-9.632-37.042-10.655 2.916z"
            />
            <ellipse fill="#b6bcbd" cx="41.167" cy="31.818" rx=".975" ry="1" />
            <path
              fill="#b6bcbd"
              d="M55.294 27.952a1.003 1.003 0 0 1-.69 1.224l-9.418 2.577a.973.973 0 0 1-1.193-.708 1.003 1.003 0 0 1 .69-1.224l9.418-2.578a.973.973 0 0 1 1.193.709z"
            />
            <ellipse cx="60.573" cy="79.29" rx="3.899" ry="4" fill="#cfd3d4" />
            <path
              fill="#b6bcbd"
              d="M58.187 77.874l3.767-1.032 1.005 3.865-3.767 1.031-1.005-3.864z"
            />
            <path
              fill="#f0f1f1"
              d="M48.316 56.899l5.113-23.262-4.252 1.164-7.473 33.997 2.165 8.328 3.794-17.256 23.871 10.21 2.599-.711-.417-1.606-25.4-10.864z"
            />
            <path
              d="M64.148 42.605c.902 3.469-2.202 16.749-2.202 16.749s-9.139-9.93-10.04-13.398A6.519 6.519 0 0 1 56.393 38a6.32 6.32 0 0 1 7.754 4.605z"
              fill="#df2c2c"
            />
            <ellipse fill="#fff" cx="58.026" cy="44.281" rx="2.827" ry="2.9" />
          </g>
          <g id="Search">
            <circle
              cx="70.836"
              cy="45.958"
              r="18.207"
              opacity=".37"
              fill="#fff"
            />
            <path
              stroke-linecap="round"
              fill="none"
              stroke="#4c4c4c"
              stroke-miterlimit="10"
              stroke-width="5"
              d="M66.326 64.435l-6.978 22.027"
            />
            <circle
              cx="70.836"
              cy="45.958"
              r="18.207"
              fill="none"
              stroke="#4c4c4c"
              stroke-miterlimit="10"
              stroke-width="5"
            />
          </g>
        </symbol>
        <symbol
          id="github"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
        </symbol>
        <symbol
          id="instagram-circle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
        </symbol>
        <symbol id="instagram" viewBox="0 0 24 24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </symbol>
        <symbol
          id="linkedin"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </symbol>

        <symbol
          id="arrow-right"
          version="1.1"
          viewBox="0 0 96 96"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12,52h62.344L52.888,73.456c-1.562,1.562-1.562,4.095-0.001,5.656c1.562,1.562,4.096,1.562,5.658,0l28.283-28.284l0,0  c0.186-0.186,0.352-0.391,0.498-0.609c0.067-0.101,0.114-0.21,0.172-0.315c0.066-0.124,0.142-0.242,0.195-0.373  c0.057-0.135,0.089-0.275,0.129-0.415c0.033-0.111,0.076-0.217,0.099-0.331C87.973,48.525,88,48.263,88,48l0,0  c0-0.003-0.001-0.006-0.001-0.009c-0.001-0.259-0.027-0.519-0.078-0.774c-0.024-0.12-0.069-0.231-0.104-0.349  c-0.039-0.133-0.069-0.268-0.123-0.397c-0.058-0.139-0.136-0.265-0.208-0.396c-0.054-0.098-0.097-0.198-0.159-0.292  c-0.146-0.221-0.314-0.427-0.501-0.614L58.544,16.888c-1.562-1.562-4.095-1.562-5.657-0.001c-1.562,1.562-1.562,4.095,0,5.658  L74.343,44L12,44c-2.209,0-4,1.791-4,4C8,50.209,9.791,52,12,52z" />
        </symbol>
        <symbol
          id="envelope"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 511.626 511.626"
        >
          <path d="M49.106 178.729c6.472 4.567 25.981 18.131 58.528 40.685 32.548 22.554 57.482 39.92 74.803 52.099 1.903 1.335 5.946 4.237 12.131 8.71 6.186 4.476 11.326 8.093 15.416 10.852 4.093 2.758 9.041 5.852 14.849 9.277 5.806 3.422 11.279 5.996 16.418 7.7 5.14 1.718 9.898 2.569 14.275 2.569h.575c4.377 0 9.137-.852 14.277-2.569 5.137-1.704 10.615-4.281 16.416-7.7 5.804-3.429 10.752-6.52 14.845-9.277 4.093-2.759 9.229-6.376 15.417-10.852 6.184-4.477 10.232-7.375 12.135-8.71 17.508-12.179 62.051-43.11 133.615-92.79 13.894-9.703 25.502-21.411 34.827-35.116 9.332-13.699 13.993-28.07 13.993-43.105 0-12.564-4.523-23.319-13.565-32.264-9.041-8.947-19.749-13.418-32.117-13.418H45.679c-14.655 0-25.933 4.948-33.832 14.844C3.949 79.562 0 91.934 0 106.779c0 11.991 5.236 24.985 15.703 38.974 10.466 13.99 21.604 24.983 33.403 32.976z" />
          <path d="M483.072 209.275c-62.424 42.251-109.824 75.087-142.177 98.501-10.849 7.991-19.65 14.229-26.409 18.699-6.759 4.473-15.748 9.041-26.98 13.702-11.228 4.668-21.692 6.995-31.401 6.995h-.578c-9.707 0-20.177-2.327-31.405-6.995-11.228-4.661-20.223-9.229-26.98-13.702-6.755-4.47-15.559-10.708-26.407-18.699-25.697-18.842-72.995-51.68-141.896-98.501C17.987 202.047 8.375 193.762 0 184.437v226.685c0 12.57 4.471 23.319 13.418 32.265 8.945 8.949 19.701 13.422 32.264 13.422h420.266c12.56 0 23.315-4.473 32.261-13.422 8.949-8.949 13.418-19.694 13.418-32.265V184.437c-8.186 9.132-17.7 17.417-28.555 24.838z" />
        </symbol>
      </svg>
    </icon-provider>
  );
}
